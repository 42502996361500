import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import mixpanel from 'mixpanel-browser';
import { Button } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'

import { Checkbox, CheckboxGroup, Tabs } from '@navikt/ds-react'
import FavoriteRecipeButton from "../components/FavoriteRecipeButton";

import FoodName from '../components/FoodName'
import ShareApp from '../components/ShareApp'
import { findRecipe } from '../util/api'
import { translatedTitle } from '../util/i18n'
import { userLanguage } from '../util/usersettings'
import {
    getShoppingList,
    loadHomeIngridients,
    saveShoppingList,
    saveUserFavoriteRecipe,
    removeUserFavoriteRecipe
} from '../util/storage'
import { findVoiceLanguage } from '../util/speech'

import './RecipesListView.css'

import stopTimePng from '../images/time_16x16.png'
import servingPng from '../images/serving_16x16.png'
import SpeakPng from '../images/speak_16.png'
import BackButton from '../images/back.png'

const getMissingIngredientsToShoppingList = (ingredients) => {
    const homeFood = loadHomeIngridients().map(ingredient => ingredient.id)

    const shoppingListIngredients = ingredients
        .filter(ingredient => !!ingredient.item.id)
        .filter(ingredient => !homeFood.includes(ingredient.item.id))
        .map(ingredient => {
            const name = translatedTitle(ingredient.item.title)
            return {name: `${ingredient.amount} ${name}`}
        })


    const currentShoppingList = getShoppingList()

    return shoppingListIngredients.filter(shoppingItem => !currentShoppingList.some(item1 => item1.name === shoppingItem.name))
}

const getMissingIngredients = (ingredients) => {
    const homeFood = loadHomeIngridients().map(ingredient => ingredient.id)

    const missingHomeIngredients = ingredients
        .filter(ingredient => !!ingredient.item.id)
        .filter(ingredient => !homeFood.includes(ingredient.item.id))

    const currentShoppingList = getShoppingList()

    return missingHomeIngredients.filter(ingredient => {
        const shoppingName = translatedTitle(ingredient.item.title)
        return !currentShoppingList.some(item1 => item1.name.includes(shoppingName))
    })
}

const addSelectedIngredientsToShoppingList = (checkedIngredients, ingredients) => {
    const toShoopingList = checkedIngredients.map( id => {
        const ingredient = ingredients.find(i => i.item.id === id)
        if (ingredient) {
            const name = translatedTitle(ingredient.item.title)
            return {name: `${ingredient.amount} ${name}`}
        } else {
            console.log('selected ingredient that is not in ingredients list', id)
        }
    })

    const currentShoppingList = getShoppingList()
    const newShoppingList = currentShoppingList.concat(toShoopingList)
    saveShoppingList(newShoppingList)
}

const ActiveInactiveTypeOfMealsDiv = (props) => {
    const { t } = useTranslation()

    if (!props.typesOfMeals) {
        return <div>{t(props.text)}</div>
    }
    const active = props.typesOfMeals.some( e => e === props.text ) ? 'active' : null
    return (
        <div className={active}>{t(props.text)}</div>
    )
}

const Author = (props) => {
    if (!props.author) {
        return <div className="recipe-author"></div>
    }
    if (!props.author.logo && !props.author.url && !props.author.name) {
        return <div className="recipe-author"></div>
    }

    const logo = props.author.logo ? <img src={props.author.logo} /> : null
    const authorContent = (
        <>
            <div key="author-name">{props.author.name}</div>
            <div key="author-logo">{logo}</div>
        </>
    )
    return (
        <div className="recipe-author">
            { props.author.url &&
                <a target="_blank" href={props.author.url} onClick={() => mixpanel.track('RecipeView-author link')}>
                   {authorContent}
                </a>
            }
            { !props.author.url &&
                <span>{authorContent}</span>
            }
        </div>
    )
}

const Ingredients = (props) => {
    const { t } = useTranslation()
    const [showShoppingCart, setShowShoppingCart] = useState(!!(getMissingIngredientsToShoppingList(props.ingredients) && getMissingIngredientsToShoppingList(props.ingredients).length))
    const [checkedIngredients, setCheckedIngredients] = useState([])

    useEffect( () => {
        setShowShoppingCart(!!(getMissingIngredientsToShoppingList(props.ingredients) && getMissingIngredientsToShoppingList(props.ingredients).length))
    }, [props])

    useEffect( () => {
        const missingIngredients = getMissingIngredients(props.ingredients)
        const selectIngredients = missingIngredients
            .map(i => i.item.id)
        setCheckedIngredients(selectIngredients)
    }, [props])

    if (!props.ingredients) {
        return <div className="recipe-ingredients"></div>
    }

    const i = props.ingredients.map( (i, idx) => {
        if (!i.item.id) {
            return <div className="ingredient-section-title" key={idx+1}><FoodName food={i.item} /></div>
        }

        return <CheckboxGroup
            legend=""
            hideLegend={true}
            onChange={(v) => {
                setCheckedIngredients(v)}
            }
            value={checkedIngredients}
        >
            <li key={(idx+1)}>
                <Checkbox value={i.item.id}>
                    {i.amount} <FoodName food={i.item} />
                </Checkbox>
            </li>
      </CheckboxGroup>
    })

    return <div className="recipe-ingredients">
        <div className="recipe-ingredients-title" key={`recipe-ingredient-title-${new Date().getTime()}`}>
            <span>{t('ingredients')}</span>
        </div>
        <ul>{i}</ul>
        { showShoppingCart &&
            <div className="recipe-ingredients-shopping-list">
                <Button variant="outline-secondary" onClick={() => {
                        mixpanel.track('RecipeView-shopping list')
                        addSelectedIngredientsToShoppingList(checkedIngredients, props.ingredients)
                        setShowShoppingCart(false)
                        setCheckedIngredients([])
                }}>{t('app.screen.recipeview.ingredients-into-shopping-list')}</Button>
            </div>
        }
        { !showShoppingCart &&
            <div className="recipe-ingredients-shopping-list">{t('app.screen.recipeview.all-ingredients-in-shopping-list')}</div>
        }
    </div>
}

const SpeechIcon = ({lang, text}) => {
    if (!lang) {
        return null
    }

    return <img src={SpeakPng} onClick={() => {
        const speak = new SpeechSynthesisUtterance(text);
        speak.lang = lang
        window.speechSynthesis.speak(speak)
    }} className="recipe-speak-icon"/>
}

const CookingProcess = (props) => {
    const { t } = useTranslation()

    const [voiceLang, setVoiceLang] = useState(null)

    useEffect(() => {
        const userLang = userLanguage()
        findVoiceLanguage(userLang).then( lang => setVoiceLang(lang) )
    }, [])

    if (!props.steps) {
        return <div className="recipe-cooking-steps"></div>
    }
    const i = props.steps.map(  (i, idx) => {
        return <li key={idx+1}>
            <SpeechIcon lang={voiceLang} text={translatedTitle(i)} />
            {translatedTitle(i)}
        </li>
    })
    return <div className="recipe-cooking-steps">
        <div className="recipe-cooking-steps-title">{t('cooking steps')}</div>
        <ul>
            {i}
        </ul>
    </div>
}

const RecipeHeader = ({ recipe }) => {
    const { t } = useTranslation()

    const showRecipeContent = () => {
        const recipeAlternativeContent = document.getElementById(`recipe-alternative-${recipe.id}`)
        if (recipeAlternativeContent) {
            recipeAlternativeContent.style.display = 'block'
        }
    }

    return <div onClick={showRecipeContent}>
        <div className="recipe-view-title">
            <div className="recipe-title">{translatedTitle(recipe.title)}</div>
        </div>
        <div>{recipe.description}</div>

        <div className="recipe-images">
        {recipe.images && recipe.images.length &&
            <img src={recipe.images[0]} />
        }
        </div>
        <div className="recipe-details">
            <div className="recipe-details-row">

                <div className="recipe-servings">
                        <img src={servingPng} alt={t('Servings')} /> &nbsp; {recipe.servings}
                </div>
                {recipe.time &&
                    <div>
                        <img src={stopTimePng} alt={t('Time')} /> &nbsp; {recipe.time.amount}{recipe.time.unit}
                    </div>
                }
                <div className="recipe-difficultyLevel">{t(recipe.difficultyLevel)}</div>
            </div>
            <div className="recipe-details-row" style={{flexGrow: 2, padding: 0}}>
                <Author author={recipe.author} />
            </div>
        </div>
    </div>
}

const SingleRecipeView = ({recipe}) => {
    const { t } = useTranslation()
    const [favoriteRecipeUpdate, setFavoriteRecipeUpdate] = useState(0) // this is necessary only to trigger update for icon/FavoriteRecipeButton

    const recipeUrl = `https://api.foodtips.me/share/facebook/${recipe.id}?lang=${userLanguage()}`

    const handleAddFavorite = () => {
        saveUserFavoriteRecipe(recipe)
        setFavoriteRecipeUpdate(favoriteRecipeUpdate + 1)
    }
    
    const handleRemoveFavorite = () => {
        removeUserFavoriteRecipe(recipe)
        setFavoriteRecipeUpdate(favoriteRecipeUpdate + 1)
    }

    return (<>
        <div className="recipe-view-top-navigation">
            <div></div>
            <div></div>
            <div>
                <FavoriteRecipeButton onClickAdd={handleAddFavorite} onClickRemove={handleRemoveFavorite} recipe={recipe} />
            </div>
            <div>
                <ShareApp url={recipeUrl} title={t('app.screen.recipeview.share-recipe')} linkTitle=" " />
            </div>
        </div>
        <RecipeHeader recipe={recipe}/>
        <div className="recipe-content">
            <div className="recipe-tags">
              {/*recipe.tags && recipe.tags.map((item, index) => (
                <span key={index} style={{fontWeight: 'bold'}}>{t(item)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
              ))*/}
            </div>
            {recipe.introText && (recipe.introText.lt || recipe.introText.no || recipe.introText.en) &&
                <div className="recipe-intro-text">{translatedTitle(recipe.introText)}</div>
            }
            <Ingredients ingredients={recipe.ingredients} productCart={recipe.productCart} setProductCart={recipe.setProductCart} />
            <CookingProcess steps={recipe.cookingProcess} />
            {recipe.footerText && (recipe.footerText.lt || recipe.footerText.no || recipe.footerText.en) &&
                <div className="recipe-footer-text">{translatedTitle(recipe.footerText)}</div>
            }
        </div>
    </>)
}

export default function RecipeView() {
    const { ids } = useParams()
    const [recipies, setRecipies] = useState(null)

    const recipeIds = ids.split(',')
    if (!recipeIds) {
        return <div>Cannot find recipe.</div>
    }

    useEffect( () => {
        if (recipeIds.length >= 1) {
            const recipePromisses = recipeIds.filter(id => id).map(async id => {
                return findRecipe(id)
            })

            Promise.all(recipePromisses).then( recipies => {
                setRecipies(recipies)
            })
        }
    }, [])

    if (!recipies) {
        return null
    }

    //window.scrollTo(0, 0)

    mixpanel.track('RecipesListView', {'id': recipies[0].id})

    const shortRecipies = recipies.slice(1, recipies.length)

    return (
        <div className="recipe-view" style={{width: '100%'}}>

            <SingleRecipeView recipe={recipies[0]} />

            {shortRecipies.map((recipe) => (
                <div className="recipe-view-alternative" key={recipe.id}>
                    <RecipeHeader recipe={recipe} />
                    <div className="recipe-alternative-content" id={`recipe-alternative-${recipe.id}`}>
                        <div className="recipe-tags">
                        {/*recipe.tags && recipe.tags.map((item, index) => (
                            <span key={index} style={{fontWeight: 'bold'}}>{t(item)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        ))*/}
                        </div>
                        {recipe.introText && (recipe.introText.lt || recipe.introText.no || recipe.introText.en) &&
                            <div className="recipe-intro-text">{translatedTitle(recipe.introText)}</div>
                        }
                        <Ingredients ingredients={recipe.ingredients} productCart={recipe.productCart} setProductCart={recipe.setProductCart} />
                        <CookingProcess steps={recipe.cookingProcess} />
                        {recipe.footerText && (recipe.footerText.lt || recipe.footerText.no || recipe.footerText.en) &&
                            <div className="recipe-footer-text">{translatedTitle(recipe.footerText)}</div>
                        }
                    </div>
                </div>
            ))}

        </div>
    )
}
